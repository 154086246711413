import {useCallback, useMemo} from "preact/compat";
import {MCDispatch} from "./model";
import {useNewId} from "./helper";

export interface MehrfacheAntwortProps {
    antwort: string
    index: number
    ausgewählt: boolean
    richtig: boolean
    aufgelöst: boolean
    dispatch: MCDispatch
}

export function MehrfacheAntwort({antwort, index, ausgewählt, aufgelöst, richtig, dispatch}: MehrfacheAntwortProps){
    const id = useNewId("MehrfachAntwort");
    const klasse = useMemo(() => {
        const k = ['antwort mehrfach'];
        if(ausgewählt) k.push('ausgewählt');
        if(aufgelöst && richtig) k.push('richtig');
        if(aufgelöst && ausgewählt && !richtig) k.push('falsch');
        return k.join(" ");
    }, [aufgelöst, ausgewählt, richtig]);
    const onChange = useCallback(
        () => dispatch({action: "markiere", index: index, ausgewählt: !ausgewählt}),
        [dispatch, index, ausgewählt])

    return (
        <div className={klasse}>
            <input type="checkbox" id={id} onChange={onChange}
                   checked={ausgewählt} disabled={aufgelöst}/>
            <label for={id} dangerouslySetInnerHTML={{__html: antwort}}></label>
        </div>
    )
}