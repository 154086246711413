import {render} from 'preact';
import {MultipeChoice} from "./MultipeChoice";
import './styles.css';


document.addEventListener("DOMContentLoaded", () => {
    console.log("Hi Multiple choice")
    document.querySelectorAll(".multiple-choice").forEach(mc => {
        console.log("hi ", mc)
        const frage = mc.querySelector<HTMLElement>(".frage");
        const antwortNode = mc.querySelector<HTMLElement>(".antworten");
        const antworten = Array.from(antwortNode?.children ?? []) as Array<HTMLElement>;
        const mehrfach = antwortNode?.classList.contains("mehrfach") ?? false;
        const erklärung = mc.querySelector<HTMLElement>(".erklärung");
        if(!frage || !antworten || !erklärung) {
            console.log("Multiple Choice Abschnitte müssen .frage, .antworten und .erklärung enthalten!");
        } else {
            const component = <MultipeChoice frage={frage}
                                             antworten={antworten}
                                             erklärung={erklärung}
                                             mehrfach={mehrfach}/>;
            mc.innerHTML = '';
            render(component, mc);
        }
    })
})