import {useMemo} from "preact/compat";
import {EinfacheAntwort} from "./EinfacheAntwort";
import {Frage} from "./Frage";
import {Erklärung} from "./Erklärung";
import {LöseAuf} from "./LöseAuf";
import {Richtig} from "./Richtig";
import {useNewId} from "./helper";
import {ZeigeErklärung} from "./ZeigeErklärung";
import {Falsch} from "./Falsch";
import {WeicheAnzeige} from "./WeicheAnzeige";
import {istKorrektBeantwortet, useMCReducer} from "./model";
import {MehrfacheAntwort} from "./MehrfacheAntwort";


export interface MultipeChoiceProps{
    frage: HTMLElement
    antworten: HTMLElement[]
    erklärung: HTMLElement
    mehrfach: boolean
}

export function MultipeChoice({frage, antworten, erklärung, mehrfach}: MultipeChoiceProps) {
    const id = useNewId("Frage");
    const richtig = useMemo(() => {
        let richtige = new Set<number>();
        antworten.forEach((elem, i) => {
            if(elem.classList.contains("richtig")) richtige.add(i);
        })
        return richtige;
    }, [antworten])
    const [state, dispatch] = useMCReducer(richtig);
    const {ausgewählt, aufgelöst, zeigeErklärung} = state;
    const korrekteAntwort = useMemo(() => istKorrektBeantwortet(state), [state])

    return (
        <section class="multiple-choice-app">
            <Frage html={frage.innerHTML}/>
            {mehrfach ?
                antworten.map((elem, index) =>
                    <MehrfacheAntwort antwort={elem.innerHTML} index={index} dispatch={dispatch}
                                      ausgewählt={ausgewählt.has(index)}
                                      richtig={richtig.has(index)}
                                      aufgelöst={aufgelöst}/>) :
                antworten.map((elem, index) =>
                <EinfacheAntwort antwort={elem.innerHTML} index={index} fragenId={id} dispatch={dispatch}
                                 ausgewählt={ausgewählt.has(index)}
                                 richtig={richtig.has(index)}
                                 aufgelöst={aufgelöst}/>)}
            <WeicheAnzeige sichtbar={aufgelöst && korrekteAntwort}><Richtig/></WeicheAnzeige>
            <WeicheAnzeige sichtbar={aufgelöst && !korrekteAntwort}><Falsch/></WeicheAnzeige>
            <WeicheAnzeige sichtbar={zeigeErklärung}><Erklärung html={erklärung.innerHTML}/></WeicheAnzeige>
            <WeicheAnzeige sichtbar={aufgelöst && !zeigeErklärung}><ZeigeErklärung dispatch={dispatch}/></WeicheAnzeige>
            <WeicheAnzeige sichtbar={!aufgelöst}><LöseAuf dispatch={dispatch}/></WeicheAnzeige>
        </section>
    )
}