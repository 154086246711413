import {useReducer} from "preact/compat";

export interface MCState {
    richtig: Set<number>
    ausgewählt: Set<number>
    aufgelöst: boolean
    zeigeErklärung: boolean
}

export type MCAction =
    | { action: "markiere nur", index: number }
    | { action: "markiere", index: number, ausgewählt: boolean }
    | { action: "löse auf" }
    | { action: "zeige Erklärung" }

export function markiere(set: Set<number>, index: number, ausgewählt: boolean) {
    const neu = new Set(set);
    if (ausgewählt) {
        neu.add(index);
    } else {
        neu.delete(index);
    }
    return neu
}

function setEq(set1: Set<any>, set2: Set<any>){
    let possiblyEqual = set1.size === set2.size;
    set1.forEach(x => possiblyEqual = possiblyEqual && set2.has(x))
    return possiblyEqual;
}

export function MCReducer(state: MCState, action: MCAction) {
    switch (action.action) {
        case "markiere":
            return {...state, ausgewählt: markiere(state.ausgewählt, action.index, action.ausgewählt)};
        case "markiere nur":
            return {...state, ausgewählt: new Set([action.index])};
        case "löse auf":
            if (state.aufgelöst) return state;
            return {
                ...state,
                aufgelöst: true,
                zeigeErklärung: !setEq(state.ausgewählt, state.richtig)
            };
        case "zeige Erklärung":
            if (state.zeigeErklärung || !state.aufgelöst) return state;
            return {
                ...state,
                zeigeErklärung: true
            };
    }
}

export function istKorrektBeantwortet(state: MCState) {
    return setEq(state.ausgewählt, state.richtig);
}

export type MCDispatch = (action: MCAction) => void

export function useMCReducer(richtig: Set<number>): [MCState, MCDispatch] {
    return useReducer(MCReducer, {
        richtig: richtig,
        ausgewählt: new Set<number>(),
        aufgelöst: false,
        zeigeErklärung: false
    })
}