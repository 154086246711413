import {ComponentChildren} from "preact";

export interface WeicheAnzeigeProps {
    sichtbar: boolean
    maxHeight?: string
    children: ComponentChildren
}

export function WeicheAnzeige ({sichtbar, maxHeight="25em", children}: WeicheAnzeigeProps){
    const klasse = `weiche-anzeige${sichtbar ? " sichtbar" : " versteckt"}`;
    const style = sichtbar ? `max-height: ${maxHeight};` : undefined;
    return (
        <div class={klasse} style={style}>{children}</div>
    )
}