import {useCallback, useMemo} from "preact/compat";
import {MCDispatch} from "./model";

export interface EinfacheAntwortProps {
    antwort: string
    index: number
    fragenId: string
    ausgewählt: boolean
    richtig: boolean
    aufgelöst: boolean
    dispatch: MCDispatch
}

export function EinfacheAntwort({antwort, index, fragenId, ausgewählt, aufgelöst, richtig, dispatch}: EinfacheAntwortProps){
    const id = useMemo(() => fragenId + index, [fragenId, index])
    const klasse = useMemo(() => {
        const k = ['antwort einfach'];
        if(ausgewählt) k.push('ausgewählt');
        if(aufgelöst && richtig) k.push('richtig');
        if(aufgelöst && ausgewählt && !richtig) k.push('falsch');
        return k.join(" ");
    }, [aufgelöst, ausgewählt, richtig]);
    const onChange = useCallback(
        () => dispatch({action: "markiere nur", index: index}),
        [dispatch, index])

    return (
        <div className={klasse}>
            <input type="radio" id={id} name={fragenId} onChange={onChange}
                   checked={ausgewählt} disabled={aufgelöst}/>
            <label for={id} dangerouslySetInnerHTML={{__html: antwort}}></label>
        </div>
    )
}